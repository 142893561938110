import {datadogLogs} from '@datadog/browser-logs';
import {datadogRum} from '@datadog/browser-rum';
import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import {createTheme, ThemeProvider as MaterialThemeProvider} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import flagsmith, {createFlagsmithInstance} from 'flagsmith/isomorphic';
import {FlagsmithProvider} from 'flagsmith/react';
import {IState} from 'flagsmith/types';
import {NextIntlProvider} from 'next-intl';
import type {IntlMessages} from 'next-intl';
// @ts-ignore - no type for next-redux-wrapper
import withRedux from 'next-redux-wrapper';
import App from 'next/app';
import type {AppContext} from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Router from 'next/router';
import React, {Fragment} from 'react';
import {Provider} from 'react-redux';
import {OutageBanner} from '../src/components/OutageBaner/OutageBanner';
import config from '../src/config';
import * as ga from '../src/libs/ga';
import getTextContrastColor from '../src/libs/textContrastColor';
import {getOrNull, isWhite} from '../src/libs/utils';
import {setUserbackIsLoaded} from '../src/reducers/guestportal';
import createStore from '../src/store/createStore';
import theme from '../src/theme';
import '../src/theme/AdyenForm.styles.css';
import '../src/theme/global.css';
import '../src/theme/reactquill.snow.css';

const VersionUpgrade = dynamic(() => import('../src/components/VersionUpgrade'), {
    ssr: false,
});

// use Google Analytics to track page views
Router.events.on('routeChangeComplete', ga.pageview);

class MyApp extends App<{store: any; messages: IntlMessages; flagsmithState: IState<string, string>}> {
    async componentDidMount() {
        const {pageProps, store, flagsmithState, router} = this.props;

        const reservationId = getOrNull('reservation.id', pageProps);
        // Remove the server-side injected CSS
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles && jssStyles.parentNode) {
            jssStyles.parentNode.removeChild(jssStyles);
        }

        const context = reservationId
            ? {
                  id: pageProps.reservation.id,
                  customer: pageProps.reservation.host_name,
                  user_id: pageProps.reservation.hid,
                  listing_id: pageProps.reservation.lid,
                  source: pageProps.reservation.source,
                  status: pageProps.reservation.status,
                  risk_color: pageProps.reservation.protect_color,
                  whitelisted: pageProps.reservation.whitelisted,
                  confirmation_code: pageProps.reservation.confirmation_code,
                  without_listing: pageProps.reservation.without_listing,
              }
            : {};

        // init DataDog RUM client and context
        datadogRum.init({
            clientToken: config.DATADOG_RUM_CLIENT_TOKEN,
            applicationId: config.DATADOG_RUM_APP_ID,
            sampleRate: 100,
            sessionSampleRate: 100,
            env: config.stage,
            service: 'guestportal',
            version: config.VERSION,
            trackResources: true,
            trackLongTasks: true,
            trackInteractions: true,
            trackFrustrations: true,
            useSecureSessionCookie: config.isProd,
            silentMultipleInit: true,
            defaultPrivacyLevel: 'mask-user-input',
            enableExperimentalFeatures: ['feature_flags'],
        });

        datadogLogs.init({
            clientToken: config.DATADOG_RUM_CLIENT_TOKEN,
            site: 'datadoghq.com',
            env: config.stage,
            forwardErrorsToLogs: true,
            sessionSampleRate: 100,
            version: config.VERSION,
            service: 'guestportal',
            forwardConsoleLogs: ['error'],
        });

        Object.keys(context).forEach((key) =>
            datadogRum.setGlobalContextProperty(`reservation.${key}`, context[key as keyof typeof context]),
        );

        // identify reservation for flagsmith
        if (context.user_id) {
            console.log('setting flagsmith identity');
            await flagsmith.identify(context.user_id, {
                customer: context.customer,
            });
        }

        // manually send flags to datadog here, instead of in flagsmith init
        // because flagsmith is initialized server-side
        // before datadog, and so does not send the flags
        const latestFlags = flagsmith.getAllFlags();
        if (latestFlags) {
            Object.keys(latestFlags).forEach((key) => {
                const flagData = latestFlags[key];

                // if flag is enabled and it has a value send the value to datadog
                // else pass flag enabled status directly
                const valueForDatadog = flagData.enabled
                    ? flagData.value
                        ? flagData.value
                        : flagData.enabled
                    : flagData.enabled;
                // @ts-ignore this is a beta feature so it is not in the types yet
                datadogRum.addFeatureFlagEvaluation(key, valueForDatadog);
            });
        }
        if (config.stage === 'prod') {
            datadogRum.startSessionReplayRecording();
        }

        // init Userback context
        const Userback = typeof window !== 'undefined' && window.Userback;
        const hideUserbackOnKiosk = !!router.query.kiosk && flagsmithState.flags?.hide_feedback_on_kiosk?.enabled;

        if (Userback && !hideUserbackOnKiosk) {
            try {
                Userback.init?.(`11202|${config.isProd ? '23305' : '70674'}|gBAPqCkFMVNBNrJYzp9aqPziq`, {
                    email:
                        getOrNull('reservation.form.email', pageProps) ||
                        getOrNull('reservation.guest_email', pageProps),
                    name:
                        getOrNull('reservation.form.full_name', pageProps) ||
                        getOrNull('reservation.guest_full_name', pageProps),
                    on_load: () => {
                        // set loaded status so we can avoid race conditions when we need to hide the widget
                        store.dispatch(setUserbackIsLoaded(true));
                    },
                    custom_data: context,
                });
            } catch (error) {
                console.error(`Failed to load bug reporting plugin: ${error}`);
            }
        }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        const {pageProps} = this.props;
        const {reservationId} = pageProps || {};
        console.error(`Exception caught in Guest Portal top-most component for reservation ${reservationId}: ${error}`);
        super.componentDidCatch(error, errorInfo);
    }

    render() {
        const {Component, store, router, pageProps, messages} = this.props;
        const {locale} = router;
        let userTheme = theme;
        const primaryColor = getOrNull('reservation.company.color_primary', pageProps);
        if (primaryColor && !isWhite(primaryColor)) {
            userTheme = createTheme({
                ...theme,
                direction: ['ar', 'he', 'fa'].includes(locale || '') ? 'rtl' : 'ltr',
                palette: {
                    ...theme.palette,
                    primary: {
                        main: primaryColor,
                        contrastText: getTextContrastColor(primaryColor),
                    },
                    secondary: {
                        main: primaryColor,
                    },
                },
            });
        }

        let header = (
            <Head>
                <title>Guest Portal</title>

                {/* Use minimum-scale=1 to enable GPU rasterization */}
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                />

                {/* ask robots not to index this site */}
                <meta name="googlebot" content="noindex" />
                <meta name="robots" content="noindex" />
                {/* 
                    
                        Autohost SDK. To test local changes, build locally, and place the bundle file in the `static` directory. 
                        Comment out the next line, and uncomment the that loads it from the static directory.
                    */}
                <script
                    src={`https://sdk.autohost.${config.isProd ? 'ai' : 'ca'}/dist/AutohostSDK.v3${
                        config.isProd ? '' : '-beta'
                    }.bundle.js`}
                />

                {/* <script src="/static/AutohostSDK.bundle.js" /> */}
            </Head>
        );

        if (pageProps && pageProps.reservation && pageProps.reservation.id) {
            const reservation = pageProps.reservation;
            let siteDomain = reservation.settings && reservation.settings.guest_portal_custom_domain;
            if (!siteDomain || siteDomain === '') {
                siteDomain = 'verifyhub.org';
            }
            header = (
                <Head>
                    <title>Check-in for {reservation.guest_first_name}</title>
                    <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
                    <meta property="og:title" content={`Check-in for ${reservation.guest_first_name}`} />
                    <meta
                        name="description"
                        content={`Hi ${reservation.guest_first_name}, please complete the following steps to confirm your upcoming reservation in ${reservation.listing_city}, ${reservation.listing_country}.`}
                    />
                    <meta
                        property="og:description"
                        content={`Hi ${reservation.guest_first_name}, please complete the following steps to confirm your upcoming reservation in ${reservation.listing_city}, ${reservation.listing_country}.`}
                    />
                    <meta
                        property="og:image"
                        content={`https:${reservation.listing_picture_thumbnail.replace(/^https?:/, '')}`}
                    />
                    <meta
                        property="og:image:secure_url"
                        content={`https:${reservation.listing_picture_thumbnail.replace(/^https?:/, '')}`}
                    />
                    <meta property="og:image:alt" content={reservation.listing_name} />
                    <meta property="og:url" content={`https://${siteDomain}/${reservation.id}`} />
                    <meta name="googlebot" content="noindex" />
                    <meta name="robots" content="noindex" />

                    {/* User feedback tool */}
                    <script src="https://static.userback.io/widget/v1.js"></script>
                    {/* 
                    
                        Autohost SDK. To test local changes, build locally, and place the bundle file in the `static` directory. 
                        Comment out the next line, and uncomment the that loads it from the static directory.
                    */}
                    <script
                        src={`https://sdk.autohost.${config.isProd ? 'ai' : 'ca'}/dist/AutohostSDK.v3${
                            config.isProd ? '' : '-beta'
                        }.bundle.js`}
                    />

                    {/* <script src="/static/AutohostSDK.bundle.js" /> */}
                </Head>
            );
        }

        return (
            <Fragment>
                {header}
                <MaterialThemeProvider theme={userTheme}>
                    <CssBaseline />
                    <FlagsmithProvider flagsmith={flagsmith} serverState={this.props.flagsmithState}>
                        <Provider store={store}>
                            <NextIntlProvider
                                messages={{...messages, ...pageProps.messages}}
                                locale={locale || 'en'}
                                timeZone={getOrNull('reservation.guest_timezone', pageProps) || 'America/Toronto'}
                            >
                                <MuiPickersUtilsProvider utils={MomentUtils} locale={locale || 'en'}>
                                    <OutageBanner />
                                    <Component router={router} {...pageProps} />
                                </MuiPickersUtilsProvider>
                                <VersionUpgrade />
                            </NextIntlProvider>
                        </Provider>
                    </FlagsmithProvider>
                </MaterialThemeProvider>
            </Fragment>
        );
    }
}

MyApp.getInitialProps = async (context: AppContext) => {
    const flagsmithSSR = createFlagsmithInstance();
    await flagsmithSSR.init({
        // fetches flags on the server
        environmentID: config.FLAGSMITH_ENVIRONMENT_KEY,
        api: config.FLAGSMITH_API_URL,
        onError: (err: Error) => {
            console.error(err);
        },
    });

    const {locale, locales, defaultLocale} = context.router;
    let messages;
    try {
        messages = require(`../locales/${locale}.json`);
    } catch (e) {
        console.error(`Failed to load requested locale ${locale} (${locales}), falling back to ${defaultLocale}`);
        try {
            messages = require(`../locales/en.json`);
        } catch (e2) {
            console.error(`Failed to load fallback translations`);
            messages = {};
        }
    }
    return {
        ...(await App.getInitialProps(context)),
        messages,
        flagsmithState: flagsmithSSR.getState(),
    };
};

export default withRedux(createStore)(MyApp);
