import {white, black, red} from './colors';

export default {
    common: {
        black,
        white,
        neutral: '#E4E7EB',
        muted: '#9EA0A4',
    },
    primary: {
        contrastText: white,
        main: '#0767DB',
        light: '#F6F9FD',
        dark: '#0B48A0',
    },
    secondary: {
        contrastText: white,
        main: '#7d58ff',
        light: '',
        dark: '#37248F',
    },
    error: {
        main: red,
    },
    success: {
        contrastText: white,
        main: '#45B880',
        light: '#F1FAF5',
        dark: '#00783E',
    },
    info: {
        contrastText: white,
        main: '#1070CA',
        light: '#F1FBFC',
        dark: '#007489',
    },
    warning: {
        contrastText: white,
        main: '#FFB822',
        light: '#FDF8F3',
        dark: '#95591E',
    },
    danger: {
        contrastText: white,
        main: '#ED4740',
        light: '#FEF6F6',
        dark: '#BF0E08',
    },
    background: {
        default: white,
        dark: '#172B4D',
        paper: white,
    },
    text: {
        primary: '#12161B',
        secondary: '#66788A',
        disabled: '#A6B1BB',
    },
    border: '#DFE3E8',
    divider: '#DFE3E8',
};
