import {createSlice} from '@reduxjs/toolkit';

export type SnackbarState = {
    open?: boolean;
    text?: any;
    action?: any;
    props?: {
        duration?: number;
        variant?: any;
    };
};

const initialState: SnackbarState = {
    open: false,
    text: null,
    action: null,
    props: {},
};

const snackbarSlice = createSlice({
    name: 'autohost/snackbar',
    initialState,
    reducers: {
        openSnackbar: {
            reducer: (state, action) => {
                state.text = action.payload.text;
                state.action = action.payload.action;
                state.props = action.payload.props || {};
                state.open = true;
            },
            prepare: (text, action, props = {}) => {
                if (Array.isArray(text)) {
                    props = text[2] || {};
                    action = text[1] || null;
                    text = text[0];
                }
                return {
                    payload: {
                        text,
                        action,
                        props,
                    },
                    meta: null,
                    error: null,
                };
            },
        },

        closeSnackbar: (state) => {
            state.text = null;
            state.action = null;
            state.props = {};
            state.open = false;
        },
    },
});

export const {openSnackbar, closeSnackbar} = snackbarSlice.actions;

export default snackbarSlice.reducer;
