const clientMiddleware = (client) => {
    return ({dispatch, getState}) => {
        return next => action => {

            if (typeof action === 'function') {
                return action(dispatch, getState);
            }

            const {promise, types, ...rest} = action; // eslint-disable-line no-redeclare
            if (!promise) {
                return next(action);
            }

            const [REQUEST, SUCCESS, FAILURE] = types;
            next({...rest, type: REQUEST});

            const actionPromise = promise(client)(getState);

            actionPromise.then(
                (payload) => next({...rest, payload, type: SUCCESS}),
                (error) => next({...rest, error, type: FAILURE})
            ).catch((error) => {
                console.error('MIDDLEWARE ERROR:', error);
                next({...rest, error, type: FAILURE});
            });

            return actionPromise;
        };
    };
};

export default clientMiddleware;