import {Snackbar} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {useFlags} from 'flagsmith/react';
import {useEffect, useState} from 'react';

/**
 * Component that displays an outage banner based on feature flag 'outage_banner_guestportal'.
 * The banner content is determined by the value of the feature flag, with default severity as 'info' and no text.
 * The banner is shown if the feature flag is enabled, and can be closed manually unless severity is 'error'.
 * The banner will automatically close if the severity is "info"
 *
 * @returns {JSX.Element} The JSX element representing the outage banner.
 */

export function OutageBanner({}) {
    const [isOpen, setIsOpen] = useState(false);
    const {outage_banner_guestportal} = useFlags(['outage_banner_guestportal']);

    const bannerValues =
        outage_banner_guestportal.value && typeof outage_banner_guestportal.value === 'string'
            ? JSON.parse(outage_banner_guestportal.value)
            : {severity: 'info', text: undefined};

    useEffect(() => {
        setIsOpen(outage_banner_guestportal.enabled);
    }, [outage_banner_guestportal]);

    return (
        <Snackbar
            open={isOpen}
            onClose={() => (bannerValues.severity !== 'error' ? setIsOpen(false) : undefined)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            autoHideDuration={bannerValues.severity === 'info' ? 10000 : undefined}
        >
            <Alert severity={bannerValues.severity} variant="filled" style={{alignItems: 'center'}}>
                <strong style={{fontSize: '1rem'}}>{bannerValues.text}</strong>
            </Alert>
        </Snackbar>
    );
}
