import Immutable from 'immutable';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';
import {configureStore} from '@reduxjs/toolkit';

import config from '../config';
import storeApiClient from './storeApiClient';
import apiClientMiddleware from './middlewares/apiClientMiddleware';
import rootReducer from '../reducers';
import {guestPortalApi} from '../reducers/guestPortalApi';

function createMiddlewares({req}: {isServer: boolean; req: any}) {
    const apiClient = new storeApiClient(req);

    let middlewares = [thunkMiddleware, apiClientMiddleware(apiClient)];

    if (config.env === 'development' && typeof window !== 'undefined') {
        middlewares.push(
            createLogger({
                level: 'info',
                collapsed: true,
                stateTransformer: (state) => {
                    let newState: {[key: string]: any} = {};

                    for (let i of Object.keys(state)) {
                        if (Immutable.Iterable.isIterable(state[i])) {
                            newState[i] = state[i].toJS();
                        } else {
                            newState[i] = state[i];
                        }
                    }

                    return newState;
                },
            }),
        );
    }

    return middlewares;
}

const create = (initialState = {}, context: any) => {
    let {isServer, req} = context;
    let middlewares = createMiddlewares({isServer, req});

    let store = configureStore({
        reducer: rootReducer,
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(middlewares).concat(guestPortalApi.middleware),
        devTools: !isServer && config.env !== 'production',
    });

    return store;
};

export default create;
