import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';

import guestportal from './guestportal';
import snackbar from './snackbar';
import {guestPortalApi} from './guestPortalApi';

export default combineReducers({
    form: formReducer,
    guestportal,
    snackbar,
    [guestPortalApi.reducerPath]: guestPortalApi.reducer,
});
